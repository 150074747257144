.get-started-now {
    background-image: url("../../../../Assets/backgrounds/GetStartedNowBg.png");
    min-height: 100vh;
    background-size: 100% 100%;
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-get-started-now-section{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 80%;
    min-height: 70vh;
    padding-top: 3%;
    padding-bottom: 4%;
    border-radius: 15px;
    background: linear-gradient(to bottom, #3E5E53 0%, #aab8b3 100%);
}

.left-side-get-started-now-section{
    max-width: 45vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.left-side-get-started-now-section h1{
    font-family: 'headerFont', sans-serif;
}

.left-side-get-started-now-section h2{
    color: var(--beige-color); 
    font-family: 'headerFont', sans-serif;
}

.left-side-get-started-now-section p{
    font-family: 'bodyFont', sans-serif;
    font-size: larger;
}

.left-side-get-started-now-section-cta {
    display: flex;
    max-height: 10vh;
    width: 88%;
}

.get-started-now-section-cta-input{
    height: 6vh;
    width: 64%;
}

.left-side-get-started-now-section-cta h6{
    font-family: 'headerFont', sans-serif;
    font-size: small;

}

.get-started-now-cta-button{
    background-color: var(--orange-color); 
    border-color: var(--orange-color);
    text-align: center;
    margin-left: 5%;
    width: 42%;
}

.get-started-now-cta-button:hover{
    background-color: var(--orange-color) !important; 
    border-color: var(--orange-color) !important;
}

@media (max-width: 500px){
    .centered-get-started-now-section{
        flex-direction: column;
        align-items: self-start;
        text-align: start;
        width: 88%;
        min-height: 100vh;
        border-radius: 25px;
        margin-bottom: 4vh;
    }
    
    .left-side-get-started-now-section{
        max-width: 75vw;
        display: flex;
        align-self: center;
    }

    .left-side-get-started-now-section h2 {
        font-size: large;
    }

    .left-side-get-started-now-section p {
        font-size: inherit;
    }

    .left-side-get-started-now-section-cta {
        flex-direction: column;
    }

    .get-started-now-section-cta-input{
        width: 110%;
    }

    .get-started-now-cta-button{
        max-width: 55vw;
        margin-top: 2%;
        height: 10vh;
        margin-left: 0;
        width: 80%;
    }

    .right-side-get-started-now-section{
        margin-top: 20%;
        width: 100%;
        margin-left: 6.5vh;

    }

    .right-side-get-started-now-section img{
        width: 75%;
    }
    .get-started-now{
        background-size: auto;
    }
}