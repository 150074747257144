.centered-verification {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 75%;
}

.centered-verification h1{
  font-family: 'headerFont', sans-serif;
}

.centered-verification h3{
  font-family: 'bodyFont', sans-serif;
}
  