.home {
    background-image: url("../../../../Assets/backgrounds/1.png");
    min-height: 100vh;
    background-size: 100% 100%;
    min-width: 100vw;
}

.centered-home-section{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 80vh;

}

.right-side-home-section{
    animation: slideInFromRight 2s forwards;
}

.left-side-home-section{
    max-width: 45vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    animation: slideInFromLeft 2s forwards;

}

.left-side-home-section h1{
    font-family: 'headerFont', sans-serif;
}

.left-side-home-section h2{
    color: var(--orange-color); 
    font-family: 'headerFont', sans-serif;
}

.left-side-home-section p{
    font-family: 'bodyFont', sans-serif;
    font-size: larger;
}

.left-side-home-section-cta {
    display: flex;
    max-height: 10vh;
    width: 88%;
}

.home-section-cta-input{
    height: 6vh;
    width: 64%;
}

.left-side-home-section-cta h6{
    font-family: 'headerFont', sans-serif;
    font-size: small;

}

.home-cta-button{
    background-color: var(--orange-color); 
    border-color: var(--orange-color);
    text-align: center;
    margin-left: 5%;
}

.home-cta-button:hover{
    background-color: var(--orange-color) !important; 
    border-color: var(--orange-color) !important;
}

@media (max-width: 500px){

    .home {
        background-size: auto;
    }

    .centered-home-section{
        flex-direction: column;
        align-items: self-start;
        text-align: start;
    }
    
    .left-side-home-section{
        max-width: 88vw;
        display: flex;
        align-self: center;
    }

    .left-side-home-section h2 {
        font-size: x-large;
    }

    .left-side-home-section p {
        font-size: inherit;
    }

    .left-side-home-section-cta {
        flex-direction: column;
    }

    .home-section-cta-input{
        width: 110%;
    }

    .home-cta-button{
        max-width: 55vw;
        margin-top: 2%;
        height: 10vh;
        margin-left: 0;
    }

    .right-side-home-section{
        margin-top: 10%;
        align-self: center;
    }
    .home{
        background-size: auto;

    }
}