.features {
    background-image: url("../../../../Assets/backgrounds/featuresBg.png");
    min-height: 100vh;
    background-size: 100% 100%;
    min-width: 100vw;
    display: flex;
    justify-content: center;
}

.centered-features-section {
    display: flex;
    justify-content: center;
}
    
.feature-select-and-mockup{
    flex-direction: row;
    min-width: 50vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.feature-desc{
    display: flex;
    align-items: center;
}

.features-header {
    display: none;
}

@media (max-width: 500px){

    .features {
        background-image: url("../../../../Assets/backgrounds/featuresBgResp.png");
    }

    .centered-features-section{
        flex-direction: column-reverse;
        height: 100%;
        margin-top: 15%;
    }
    
    .centered-features-section h1{
        font-size:large;
    }
    

    .feature-select-and-mockup{
        flex-direction: row;
        display: flex;
        justify-content: space-around;
    }

    .features-header {
        display: block;
        font-family: 'headerFont', sans-serif;
    }
    
    .features-header{
        display: none;
    }

    .features-selection{
        
    }
}