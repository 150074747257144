.our-story {
    background-image: url("../../../../Assets/backgrounds/OurStoryBg.png");
    background-size: 100% 100%;
    min-width: 100vw;
    display: flex;
    justify-content: center;
}

.centered-our-story-section{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 80vh;
    flex-direction: column;
    width: 80%;
}

.centered-our-story-section h1{
    font-family: 'headerFont', sans-serif;
    animation: fadeIn 2s ease-in forwards;
}

.centered-our-story-section p{
    font-family: 'bodyFont', sans-serif;
    font-size: larger;
    text-align: start;
    animation: fadeIn 2s ease-in forwards;
}


@media (max-width: 500px){

    .our-story {
        background-image: url("../../../../Assets/backgrounds/featuresBgResp.png");
        background-size: 100%;
    }

    .centered-our-story-section{
        text-align: start;
        justify-content: space-evenly;
    }

    .centered-our-story-section h1{
        margin-bottom: 10vh;
    }

    .centered-our-story-section p{
        font-size: inherit;
    }
    
}