.nav{
    color: #697077;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 1rem;
    padding: 0 1rem;
    height: 12.6vh;
}

.navbar-brand{
    margin-top: 1%;
}

.darb-navbar{
    animation: dropFromTop 1s ease-in-out forwards;
}

.nav ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
    padding-right: 40px;
}

.nav a{
    color: #214D51;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
}

.headerLink {
    font-family: 'headerFont', sans-serif;
    padding: 5px;
    margin: 1%;
}

.orange-header-cta{
    color: var(--orange-color); 
    border: 2px solid var(--orange-color); /* Border color */
    border-radius: 5px; /* Adjust the radius as needed */
    padding: 10px 20px;
}

.orange-header-cta :hover{
    color: var(--white-color) !important;
}

@media (max-width: 600px){
    .nav ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        gap: 0rem;
        padding-right: 0px;
    }
    .custom {
        padding: 0px;
    }
}

@media (max-width: 500px){
    .navbar-toggler{
        border-color: transparent;
        margin-top: -5%;
    }

    .navbar-toggler:focus{
        border-color: transparent;
    }

    .darb-navbar{
        flex-direction: row-reverse;
        justify-content: start !important;
    }
}