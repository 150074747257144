.centered-password {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reset-password-box {
  width: 50%;
  height: 53%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.reset-password-box h1{
  margin: 0px;
  font-family: 'headerFont', sans-serif;
  font-size: x-large;
}

.reset-password-box p{
  margin: 0px;
  font-family: 'bodyFont', sans-serif;
  font-size: large;
}

.reset-password-box form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reset-password-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}

.reset-password-input {
  display: flex;
  flex-direction: column;
  width: 520px;
  height: 65px;
  justify-content: space-between;
  margin-top: 10px;
}

.reset-password-input input{
  height: 60px;
  background-color: #f2f6f7;
  border: 0;
  border-radius: 5px;
  padding-left: 10px;
}



.reset-password-input input:focus{
  background-color: white;
}

.reset-password-input label{
  margin-bottom: 10px;
}

.reset-password-error {
  color: red;
  margin-top: 10px;
}

.reset-password-box form button{
  width: 520px;
  background-color: var(--orange-color);
  height: 50px;
  margin-top: 20px;
  color: white;
  border-radius: 5px;
  border: 0;
  font-family: 'headerFont', sans-serif;
}

.reset-password-box form button:hover{
  cursor: pointer;
}

@media (max-width: 500px) {
  .reset-password-box {
    width: 90%;
    height: 70%;
  }

  .reset-password-input {
    width: 283px;
  }

  .reset-password-box form button{
    width: 283px;
  }

  .reset-password-content {
    padding: 10px;
  }
}
  