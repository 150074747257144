.features-title {
    display: flex;
    align-items: center;
  }
  
  .orange-line {
    width: 5vw; 
    height: 2px;
    background-color: var(--orange-color);
    margin-right: 10px; 
  }
  
  .features-title h4 {
    color: var(--orange-color);
    letter-spacing: 0.2em; 
    margin: 0;
    font-family: 'bodyFont', sans-serif;
  }

.features-description {
    text-align: start;
    display: flex;
    flex-direction: column;
    max-width: 40vw;
    animation: fadeIn 2s forwards;
}

.features-description h1{
    font-family: 'headerFont', sans-serif;
    color: var(--green-color);
}

.features-description p{
    font-family: 'bodyFont', sans-serif;
}

@media (max-width: 500px){
    .features-title{
        display: none;
    }

    .features-description{
        max-width: 80vw;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-left: 10vw;
    }
}