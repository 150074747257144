@font-face {
    font-family: 'headerFont';
    src: url('./fonts/sequoia-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'bodyFont';
    src: url('./fonts/laro-soft-regular.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  