.delete-account-guide {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
}

.delete-account-guide h1 {
    font-family: 'headerFont', sans-serif;
    color: var(--green-color);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.guide-content {
    margin-top: 2rem;
    font-family: 'bodyFont', sans-serif;
}

.guide-content p {
    line-height: 1.6;
    font-size: 1.1rem;
}

.guide-content ol {
    margin: 1.5rem 0;
    padding-left: 2rem;
}

.guide-content li {
    margin: 1rem 0;
    line-height: 1.5;
}

.warning-note {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #fff3f3;
    border-left: 4px solid #ff4444;
    border-radius: 4px;
}

.warning-note p {
    margin: 0;
}

.delete-account-form {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
}

.form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.delete-account-form button {
    background-color: #dc3545;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-account-form button:hover {
    background-color: #c82333;
}

.success-message {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #d4edda;
    color: #155724;
    border-radius: 4px;
}

.privacy-information {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.privacy-information h2 {
    font-size: 1.5rem;
    margin-bottom: 16px;
}

.privacy-information ul {
    margin-left: 20px;
    margin-bottom: 16px;
}

.privacy-information ul ul {
    margin-top: 8px;
    margin-bottom: 8px;
}

.privacy-information li {
    margin-bottom: 8px;
}