.footer{
    background-color: #214D51;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 1rem;
    padding: 0 1rem;
    height: 9.7vh;
    left:0;
    bottom:0;
    right:0;
    font-family: 'bodyFont', sans-serif;
}

.CompanyName{
    font-size: 14px;
    padding-left: 70px;
}

.footer ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
    padding-right: 70px;
}

.footer a{
    color: #fff;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    /* padding: .25px; */

}
.footer text{
    color: #fff;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    /* padding: .25px; */

}

.footer li:hover {
    background-color: #777;
}

.custom {
    padding: 5px;
}

@media (max-width: 600px){
    .CompanyName{
        font-size: 14px;
        padding-left: 0px;
    }
    .footer ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        gap: 1rem;
        padding-right: 0px;
    }
}