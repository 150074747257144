.single-feature-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--green-color);
    font-family: 'headerFont', sans-serif;
    border-color: var(--grey-color);
    border-radius: 35px;
    border: solid 0.5px;
    padding: 8%;
    animation: slideInFromLeft 2s forwards;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.single-feature-selection:hover {
    cursor: pointer;
}

.active-selection {
    background-color: var(--green-color);
    color: white;
}

.single-feature-selection p{
    margin-bottom: 0;
}

.features-selection {
    width: 18vw;
}

@media (max-width: 500px){
    .single-feature-selection{
        height: 6vh;
        width: 14vh;
        border-radius: 35px;
        flex-direction: column;
        justify-content: center;
    }

    .single-feature-selection p{
        font-size: x-small;
    }

    .features-selection{
        width: 20vw !important;
    }
}